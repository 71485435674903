.home {
  position: relative;
  width: 100%;
  height: 350px;
  background: url("../public/images/street8.jpg");
  background-size: cover;
  overflow: hidden;
  background-position: 0px;
  animation: bgmove 60s linear infinite;
}

@keyframes bgmove {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 5000px;
  }
}

.car {
  position: absolute;
  bottom: 3%;
  right: 0%;
  width: 41%;
  height: 26%;
  background: url("../public/images/car4.png") no-repeat;
  /* animation: car 5s infinite linear; */
}
@keyframes car {
  0% {
    right: 0%;
  }
  40% {
    right: 60%;
  }
  100% {
    right: 100%;
  }
}
.tyre {
  animation: tyre 3s infinite linear;
}
@keyframes tyre {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.left {
  position: absolute;
  top: 35%;
  left: 15%;
}
.right {
  position: absolute;
  top: 32%;
  right: 31%;
}
/* .rim{
 position:absolute;
 top:30%;
 left:27%;
 width:30px  ;
 height:30px;
 border-radius:50%;
 border:2px silver dotted;
 
 
 
 } */
