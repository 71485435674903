
.font-color {
    color: #1F2667;
    font-family: 'Patua One';
    font-size: 3rem;
}

.special-height {
    height: 850px;
}

.text-center p {
    font-family: 'Rubik', sans-serif;
    font-size: 130%;
    color: #767676;
}

.text-center h1 {
    color: #1F2667;
    font-family: 'Patua One';
    font-size: 3rem;
}

.text-center div input,
textarea {
    background-color: #c5c2c2;
    margin: 10px;

}

.text-center div input:focus {
    background-color: #ffffff;
    margin: 10px;

}

.text-center div button {
    background-color: #43b8f3;
    border-color: #43b8f3;
    color: #000000;
    font-size: large;
    font-weight: 500;
}

.text-center div button:hover {
    background-color: #000000;
    border-color: #000000;
    color: #43b8f3;
}

.box-content {
    background-color: white;
    box-shadow: 0 10px 4px rgba(35, 35, 35, 0.37);
    border-radius: 13px;
}

.contact-form {
    height: 50vh;
    background-color: #f3f3f3;
}

.contact-form h1 {
    color: #1F2667;
    font-family: 'Patua One';
    font-size: 3rem;
}

.industries h1 {
    color: #1F2667;
    font-family: 'Patua One';
    font-size: 3rem;
    margin-top: 10px;
}

.about h1 {
    color: #1F2667;
    font-family: 'Patua One';
    font-size: 3rem;
    margin-top: 10px;
}

.about h3 {
    color: #4f46e5;
   //background: linear-gradient(90deg, #4f46e5 0%, #000851 100%);
}

.text-center div input:focus,
textarea:focus {
    outline: 3px solid #7bb8f9 !important;
    /* Change the color to your preferred focus color */
}


.margin-left {
    margin-left: 5%;
}

.icon {
    font-size: 170%;
    margin-bottom: 10%;
    color: #4f46e5;
}

.margin-bottom {
    margin-bottom: 20%;
}

.h-w {
    height: 50%;
    width: 65%;
}

.navbar ul li:hover {

    color: #4f46e5;

}

.icon-color {
    color: #4f46e5;
}

.img-class {  width: 30px;
    margin-bottom: 10px;}
.main-content {
    margin-top: 20px;
}

.bd-radius {
    border-radius: 30px;
}

.second-list {

    color: #000000;
}

.nn:hover {
    background-color: white !important;

}

.bd-radius1 {
    border-radius: 20px;
}

.mrt {
    margin-top: 8%
}

.img-st {
    width: 50px;
    border-radius: 52%;
    height: 50px;
    margin: 0 0 10px 0;
}


.img-sd {
    width: 30px;
    border-radius: 50%;
    height: 30px;

}

.full-width {
    width: 100% !important;
    max-width: 1700px;
}

.card-content {
    margin: 0 0 0 20px;
}